/* 0-600px Phone
    600px-900px Table Portrait
    900px-1200px Table landscape
    1200-1800 is where our normal styles apply
    1800 + Big Desktop
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media only screen and (max-width: 37.5em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 75em) {
    html {
      font-size: 56.25%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (min-width: 112.5em) {
    html {
      font-size: 75%; } }

body {
  box-sizing: inherit; }
  @media only screen and (max-width: 56.25em) {
    body {
      padding: 0; } }

img {
  margin: 0 auto;
  height: auto;
  width: 100%; }

::selection {
  background-color: #2EABBF;
  color: #fff; }

.u-center-text {
  text-align: center !important; }

.u-margin-bottom-small {
  margin-bottom: 2rem !important; }

.u-margin-bottom-medium {
  margin-bottom: 4rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-medium {
      margin-bottom: 3rem !important; } }

.u-margin-bottom-big {
  margin-bottom: 8rem !important; }
  @media only screen and (max-width: 56.25em) {
    .u-margin-bottom-big {
      margin-bottom: 5rem !important; } }

.u-margin-bottom-top {
  margin-top: 12rem !important; }

.u-margin-bottom-huge {
  margin-top: 10rem !important; }

.u-margin-top-bottom {
  margin: 3rem 0  !important; }

body {
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: 1.7;
  color: #777 !important;
  margin: auto;
  font-size: 16px;
  box-sizing: inherit; }

.heading-primary {
  color: #fff;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem; }
  .heading-primary--main {
    display: block;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 0.5rem;
    text-align: center;
    animation-name: moveInLeft;
    animation-duration: 1.5s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--main {
        letter-spacing: 1rem;
        font-size: 3rem; } }
  .heading-primary--sub {
    display: block;
    letter-spacing: 1.2rem;
    font-weight: 700;
    animation: moveInRight;
    animation-duration: 1.5s;
    animation-timing-function: ease-out; }
    @media only screen and (max-width: 37.5em) {
      .heading-primary--sub {
        letter-spacing: .5rem; } }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  background-image: linear-gradient(to right, #2EABBF, grey);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: .2rem;
  transition: all .2s; }
  @media only screen and (max-width: 56.25em) {
    .heading-secondary {
      font-size: 3rem; } }
  @media only screen and (max-width: 37.5em) {
    .heading-secondary {
      font-size: 2.5rem; } }

.heading-tertiary {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase; }

.paragraph {
  font-size: 2rem; }
  .paragraph:not(:last-child) {
    margin-bottom: 2.5rem; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translate(100px); }
  80% {
    transform: translate(-100px); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(-100px); }
  80% {
    transform: translateX(100px); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px); }
  100% {
    opacity: 1;
    transform: translate(0); } }

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 6s;
  -moz-animation: fadeIn ease 6s;
  -o-animation: fadeIn ease 6s;
  -ms-animation: fadeIn ease 6s; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#header {
  background: url("../../assets/images/hero-image.jpg") no-repeat center center #2EABBF;
  width: 100%;
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* padding: 10rem 0; */
  display: flex;
  /* margin: 0 auto; */
  opacity: 0.9;
  filter: alpha(opacity=100);
  justify-content: center;
  align-items: center;
  /* overflow: auto; */
  clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 98%); }
  @media only screen and (max-width: 37.5em) {
    #header {
      clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 99%);
      height: 90vh; } }
  #header .header-cool-facts {
    justify-content: center;
    flex-flow: column;
    align-items: center;
    text-align: center;
    width: 100%;
    background: rgba(9, 57, 65, 0.8);
    background-size: cover;
    color: white;
    padding: 2rem;
    display: flex;
    height: inherit;
    text-align: center; }
    #header .header-cool-facts p {
      color: white;
      justify-content: center;
      flex-flow: column;
      align-items: center;
      text-align: center;
      animation: fadeIn 2s ease-in; }
  #header .header-button a i {
    font-size: 3.5rem;
    text-decoration: none !important; }
  #header .header-button a:hover {
    text-decoration: none !important; }

section {
  width: 100%;
  height: auto; }
  section .about-me-container {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%; }
    section .about-me-container .about-me-content {
      width: 60%; }
      @media only screen and (max-width: 37.5em) {
        section .about-me-container .about-me-content {
          width: 95%;
          text-align: center; } }
      section .about-me-container .about-me-content i {
        color: #2EABBF;
        margin-right: 1rem; }

.navbar-container {
  background-color: #202020;
  opacity: 0.84;
  display: flex;
  align-self: center;
  justify-content: space-between;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 80px;
  line-height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10; }
  .navbar-container a {
    text-decoration: none;
    color: #fff; }
    .navbar-container a.active {
      color: #2EABBF;
      filter: brightness(110%); }
    .navbar-container a.navbar-brand {
      display: block;
      width: 100%;
      height: inherit;
      padding: 0 15px;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 400;
      margin-right: 1rem;
      letter-spacing: 2px; }
      .navbar-container a.navbar-brand:hover {
        color: #2EABBF; }
  .navbar-container ul.navigation-links {
    list-style: none;
    height: inherit;
    line-height: inherit;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around; }
    @media only screen and (max-width: 37.5em) {
      .navbar-container ul.navigation-links {
        width: 40%;
        height: 52rem; } }
    .navbar-container ul.navigation-links :nth-child(4) {
      display: hidden; }
    .navbar-container ul.navigation-links li {
      display: inline-block; }
      .navbar-container ul.navigation-links li a {
        text-decoration: none;
        display: block;
        text-align: center;
        width: 100%;
        padding: 0 10px;
        font-size: 2rem; }
        .navbar-container ul.navigation-links li a:hover {
          color: #2EABBF; }
  .navbar-container .navbar-toggler {
    display: none;
    width: auto; }
  .navbar-container .container {
    position: relative;
    height: 100vh;
    color: #fff; }
  .navbar-container .container h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20vh; }
  @media only screen and (max-width: 56.25em) {
    .navbar-container {
      padding: 0;
      width: 100%; }
      .navbar-container.navbar-brand {
        width: 60rem; }
      .navbar-container ul.navigation-links {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-flow: row wrap;
        justify-content: space-evenly;
        width: 100%;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.9);
        /* height: auto; */
        height: 100vh;
        max-height: 0;
        overflow: hidden;
        transition: all ease-in-out 0.3s;
        top: 80px; }
        .navbar-container ul.navigation-links :nth-child(4) {
          display: none; }
        .navbar-container ul.navigation-links.open {
          max-height: 40rem;
          position: absolute;
          opacity: 0.95; }
        .navbar-container ul.navigation-links li:nth-child(-n+3) {
          width: 100%; }
          .navbar-container ul.navigation-links li:nth-child(-n+3) a {
            padding: 0; }
        .navbar-container ul.navigation-links li:nth-child(n+5) {
          margin: 0 auto;
          text-align: center;
          width: 32.22%; }
          .navbar-container ul.navigation-links li:nth-child(n+5) a {
            padding: 0; }
      .navbar-container .navbar-toggler {
        display: block;
        position: relative;
        height: 40px;
        top: 20px;
        right: 20px;
        background-color: transparent;
        color: #fff;
        border: 3px solid #fff;
        /* border: none; */
        /* border-radius: 4px; */
        outline: none;
        padding: 0 5px;
        cursor: pointer; }
        .navbar-container .navbar-toggler span,
        .navbar-container .navbar-toggler span::before,
        .navbar-container .navbar-toggler span::after {
          display: block;
          content: '';
          background-color: #fff;
          height: 3px;
          width: 28px;
          border-radius: 4px;
          transition: all ease-in-out 0.3s; }
        .navbar-container .navbar-toggler span::before {
          transform: translateY(-8px); }
        .navbar-container .navbar-toggler span::after {
          transform: translateY(5px); }
        .navbar-container .navbar-toggler.open-navbar-toggler span {
          background-color: transparent; }
          .navbar-container .navbar-toggler.open-navbar-toggler span::before {
            transform: translateY(0px) rotate(45deg); }
          .navbar-container .navbar-toggler.open-navbar-toggler span::after {
            transform: translateY(-3px) rotate(-45deg); } }

.card-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly; }
  @media only screen and (max-width: 37.5em) {
    .card-container {
      width: 100%;
      height: auto; } }
  .card-container .card {
    height: 48rem;
    width: 27%;
    margin: 2rem;
    justify-content: center;
    border: 0.6px solid rgba(141, 140, 140, 0.699);
    transition: all 0.5s ease;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.6s ease; }
    @media only screen and (max-width: 56.25em) {
      .card-container .card {
        width: 40%; } }
    @media only screen and (max-width: 37.5em) {
      .card-container .card {
        width: 80%;
        height: 52rem; } }
    .card-container .card:hover {
      box-shadow: 0 8px 16px 0 #2EABBF; }
    .card-container .card .image-container {
      width: 100%;
      height: 24rem; }
      .card-container .card .image-container img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .card-container .card .card-body {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: space-between;
      width: 100%;
      height: 50%; }
      .card-container .card .card-body .card-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: transparent; }
        .card-container .card .card-body .card-header .card-title {
          font-size: 2rem; }
        .card-container .card .card-body .card-header i {
          font-size: 2rem;
          color: #2EABBF; }
          .card-container .card .card-body .card-header i:hover {
            filter: brightness(110%); }
      .card-container .card .card-body .card-description p {
        font-size: 1.5rem; }
      .card-container .card .card-body .card-technologies {
        width: 100%; }
        .card-container .card .card-body .card-technologies h5 {
          color: grey; }
        .card-container .card .card-body .card-technologies p {
          font-size: 1.5rem; }
      .card-container .card .card-body .card-button {
        width: 100%;
        text-align: center;
        margin-top: 1.5rem; }
        .card-container .card .card-body .card-button a {
          text-align: center;
          width: 90%;
          outline: none;
          border: 1px solid grey;
          font-size: 1.5rem;
          padding: 0.5rem;
          border-radius: 1rem;
          background-color: #2EABBF;
          color: #fff;
          appearance: button;
          text-decoration: none;
          font-weight: bold;
          margin-top: 0.2rem; }
        .card-container .card .card-body .card-button:nth-child(1), .card-container .card .card-body .card-button :hover {
          filter: brightness(110%); }
        .card-container .card .card-body .card-button--double {
          justify-content: space-around;
          display: flex; }
          .card-container .card .card-body .card-button--double a {
            width: 48%; }

.working_update_container {
  width: 100%;
  height: auto;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media only screen and (max-width: 37.5em) {
    .working_update_container {
      display: none; } }
  .working_update_container iframe {
    display: block; }

#particles-js {
  /* color: #18a0e0; */
  height: inherit;
  width: 100%;
  position: absolute;
  z-index: -1;
  margin: 0px; }

.button {
  width: 100%;
  height: 30px;
  background-color: #2EABBF;
  border-radius: 0.5rem;
  color: white;
  font-weight: bold;
  font-size: 1.5rem; }
  .button:hover {
    filter: brightness(110%); }
  .button a {
    width: 100%;
    display: block;
    background-color: #2EABBF; }

.header-button {
  display: flex;
  width: auto;
  align-content: center;
  justify-content: center; }
  .header-button a {
    text-decoration: none;
    border-radius: 35px;
    background-color: #2EABBF;
    color: white;
    padding: 0.8rem;
    display: flex;
    justify-self: center; }
  .header-button:hover {
    filter: brightness(110%); }

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .form-container form {
    border-radius: 25px;
    padding: 1.5rem;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: auto;
    background: #333;
    transition: all 0.6s ease; }
    @media only screen and (max-width: 37.5em) {
      .form-container form {
        width: 85%; } }
    .form-container form:hover {
      box-shadow: 0 8px 16px 0 #2EABBF; }
    .form-container form textarea.contact-form-input {
      height: 10rem;
      resize: none; }
    .form-container form .contact-form-input {
      border-radius: 10px;
      height: 3.5rem;
      width: 100%;
      outline: none;
      font-size: 1.5rem;
      color: #2EABBF; }
      .form-container form .contact-form-input:focus {
        border: 2px solid #2EABBF; }
    .form-container form label {
      color: white;
      font-size: 1.8rem;
      margin-top: 0.5rem; }

.carousel.slide.carousel-override img {
  font-size: 1rem; }

footer {
  width: 100%;
  height: auto;
  background: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem; }
  footer .tia_elena_copy-rights {
    display: flex;
    justify-content: center;
    align-items: center; }
    footer .tia_elena_copy-rights p {
      font-size: 1.6rem; }
      footer .tia_elena_copy-rights p a {
        color: #2EABBF; }
      @media only screen and (max-width: 37.5em) {
        footer .tia_elena_copy-rights p {
          font-size: 1.3rem; } }
